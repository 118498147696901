import { Builder } from '@/lib/types/builder';
import { Countries } from '@/lib/types/countries';
import { endpoints } from '@/lib/services/mediakit';
import { Categories } from '@/lib/types/categories';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { ThemeFilter } from '@shared/constants/themeFilter';
import { FiniteStates } from '@shared/constants/finiteStates';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mkitDefaults } from '@shared/constants/mkit-defaults';
import { dynamicCards, PLATFORM_ALL } from '@shared/constants';
import { Layout as ILayout, Layouts } from 'react-grid-layout';
import { endpoints as authEndpoints } from '@/lib/services/auth';
import { ComponentNames } from '@shared/constants/componentNames';
import { BackgroundTypes } from '@shared/constants/background-types';
import { profileAvatarShapes } from '@shared/constants/profileSizes';
import { LayoutBreakpoints } from '@shared/constants/layoutBreakpoints';
import { ComponentsFilters } from '@shared/constants/componentsFilters';
import { InternetConnectionStatus } from '@shared/constants/internetConnectionStatus';

const initialState: Builder.SliceState = {
  counter: 0,
  extraData: {},
  mediakits: [],
  gridLayoutCols: 4,
  drawerIsOpen: false,
  uploadImageStates: {},
  theme: {
    colors: {},
  },
  isSavingMediakit: false,
  themeDrawerIsOpen: false,
  themeDrawerHeight: '88vh',
  shareModalIsOpen: false,
  builderPreviewMode: 'desktop',
  allElementsAreSelected: false,
  avatarState: FiniteStates.IDLE,
  gridLayoutProps: { margin: [24, 24] },
  gridLayoutBreakpoint: LayoutBreakpoints.xxs,
  creatingQueryStatus: QueryStatus.uninitialized,
  currentMediakit: {
    allLayouts: {
      [LayoutBreakpoints.sm]: [],
      [LayoutBreakpoints.xxs]: [],
    },
    theme: mkitDefaults.content.theme,
    profile: mkitDefaults.content.profile,
  },
  internetConnectionStatus: InternetConnectionStatus.online,
};

const {
  linkCrawl,
  uploadImage,
  uploadAvatar,
  editMediakit,
  fetchMediakit,
  fetchMediakits,
  fetchComponents,
  fetchThemeColors,
} = endpoints;

export const builderSlice = createSlice({
  initialState,
  name: 'builder',
  reducers: {
    onResetTheme(state) {
      state.themeDrawerHeight = '88vh';
      state.currentMediakit.theme = void 0;
    },
    onShowDrawer(state) {
      state.drawerIsOpen = true;
      state.themeDrawerIsOpen = false;
      state.activeItem = undefined;
    },
    onCloseDrawer(state) {
      state.drawerIsOpen = false;
    },
    onOpenShareModal(state) {
      state.shareModalIsOpen = true;
    },
    onCloseShareModal(state) {
      state.shareModalIsOpen = false;
    },
    onShowThemeDrawer(state) {
      state.themeDrawerIsOpen = true;
      state.drawerIsOpen = false;
      state.activeItem = undefined;
    },
    onCloseThemeDrawer(state) {
      state.themeFilter = undefined;
      state.themeDrawerIsOpen = false;
    },
    onEmptyReactGridLayout(state) {
      if (state.allElementsAreSelected) {
        sessionStorage.setItem(
          'previousLayouts',
          JSON.stringify(state.currentMediakit.allLayouts),
        );

        state.currentMediakit.allLayouts[LayoutBreakpoints.sm] = [];
        state.currentMediakit.allLayouts[LayoutBreakpoints.xxs] = [];

        state.activeItem = undefined;
        state.allElementsAreSelected = false;
      }
    },
    onRestorePreviousLayout(state) {
      const previousLayouts = sessionStorage.getItem('previousLayouts');

      if (previousLayouts) {
        sessionStorage.removeItem('previousLayouts');

        state.currentMediakit.allLayouts = JSON.parse(previousLayouts);
      }
    },
    onCopyItem(state, action: PayloadAction<any>) {
      try {
        const item: any = action.payload;

        if (!state.uploadImageStates[item.i]) {
          const uid = `${item.name}_+_` + state.counter;

          const sumSM = state.currentMediakit.allLayouts[
            LayoutBreakpoints.sm
          ].reduce((acc, curr) => {
            acc = curr.x + curr.w;
            return acc;
          }, 0);

          let posSM = sumSM % 4;

          const sumXXS = state.currentMediakit.allLayouts[
            LayoutBreakpoints.xxs
          ].reduce((acc, curr) => {
            acc = curr.x + curr.w;
            return acc;
          }, 0);

          let posXXS = sumXXS % 2;

          const isEmpty =
            state.currentMediakit.allLayouts[LayoutBreakpoints.sm].length === 0;

          state.currentMediakit.allLayouts[LayoutBreakpoints.sm].push({
            w: action.payload.w,
            h: action.payload.h,
            x: posSM,
            y: isEmpty ? 0 : Infinity,
            i: uid,
            moved: false,
            static: false,
          });

          state.currentMediakit.allLayouts[LayoutBreakpoints.xxs].push({
            w: action.payload.w,
            h: action.payload.h,
            x: posXXS,
            y: isEmpty ? 0 : Infinity,
            i: uid,
            moved: false,
            static: false,
          });

          if (dynamicCards.includes(action.payload.name as ComponentNames)) {
            if (action.payload.info) {
              state.extraData[uid] = {
                ...state.extraData[uid],
                ...action.payload.info,
              };
            }
          }

          state.activeItem = uid;

          state.counter += 1;

          state.drawerIsOpen = false;
        }
      } catch (e) {
        console.error(e);
      }
    },
    onBulkUpdate(state, action: PayloadAction<any>) {
      state.extraData = {
        ...state.extraData,
        ...action.payload.extraData,
      };

      state.currentMediakit.allLayouts = action.payload.allLayouts;
    },
    onChangeName(state, action: PayloadAction<string>) {
      if (!state.currentMediakit.profile.name) {
        state.currentMediakit.profile.name = {
          value: '',
        };
      }

      state.currentMediakit.profile.name.value = action.payload;
    },
    onRemoveSocial(state, action: PayloadAction<number>) {
      if (state.currentMediakit.profile.social_links) {
        state.currentMediakit.profile.social_links.splice(action.payload, 1);
      }
    },
    onChangeCtaText(state, action: PayloadAction<string>) {
      state.currentMediakit.profile.cta = action.payload;
    },
    onChangeTextarea(state, action: PayloadAction<string>) {
      state.currentMediakit.profile.bio = action.payload;
    },
    onRemoveCategory(state, action: PayloadAction<number>) {
      state.currentMediakit.profile.categories?.splice(action.payload, 1);
    },
    onChangeAllLayouts(state, action: PayloadAction<Layouts>) {
      state.currentMediakit.allLayouts = action.payload;
    },
    onRemoveReactGridItem(state, action: PayloadAction<number>) {
      Object.keys(state.currentMediakit.allLayouts).forEach((breakpoint) => {
        const current =
          state.currentMediakit.allLayouts[breakpoint][action.payload];

        state.currentMediakit.allLayouts[breakpoint].splice(action.payload, 1);

        if (current) {
          const type = current.i.split('_+_')[0] as ComponentNames;

          if (dynamicCards.includes(type)) {
            if (!state.extraData) {
              state.extraData = {};
            }

            delete state.extraData[current.i];
          }
        }
      });

      state.activeItem = undefined;
    },
    onAddItem(state, action: PayloadAction<Omit<ILayout, 'x' | 'y'>>) {
      try {
        const { sm, xxs } = LayoutBreakpoints;

        sessionStorage.removeItem('previousLayouts');

        const uid = `${action.payload.i}_+_` + state.counter;

        const sumSM = state.currentMediakit.allLayouts[sm].reduce(
          (acc, curr) => {
            acc = curr.x + curr.w;

            return acc;
          },
          0,
        );

        let posSM = sumSM % 4;

        const sumXXS = state.currentMediakit.allLayouts[xxs].reduce(
          (acc, curr) => {
            acc = curr.x + curr.w;

            return acc;
          },
          0,
        );

        let posXXS = sumXXS % 2;

        const layout = state.currentMediakit.allLayouts[sm];
        const layoutLength = layout.length;

        const isEmpty = layoutLength === 0;

        state.currentMediakit.allLayouts[sm].push({
          w: action.payload.w,
          h: action.payload.h,
          x: posSM,
          y: isEmpty ? 0 : Infinity,
          i: uid,
          moved: false,
          static: false,
        });

        state.currentMediakit.allLayouts[xxs].push({
          w: action.payload.w,
          h: action.payload.h,
          x: posXXS,
          y: isEmpty ? 0 : Infinity,
          i: uid,
          moved: false,
          static: false,
        });

        if (dynamicCards.includes(action.payload.i as ComponentNames)) {
          if (!state.extraData) {
            state.extraData = {};
          }

          if (action.payload.i !== ComponentNames.basics_service_list) {
            state.extraData[uid] = {
              value: '',
              title: '',
              image_url: '',
            };
          }
        }

        state.activeItem = uid;

        state.counter += 1;
        state.drawerIsOpen = false;

        setTimeout(() => {
          const elem = document.querySelector('.react-grid-item.active');
          elem?.scrollIntoView({ behavior: 'smooth' });
        }, 400);
      } catch (e) {
        console.error(e);
      }
    },
    onSelectLocation(state, action: PayloadAction<Countries.Country>) {
      state.currentMediakit.profile.location = action.payload;
    },
    onChangeAllElementsSelection(state, action: PayloadAction<boolean>) {
      state.activeItem = undefined;

      state.allElementsAreSelected = action.payload;
    },
    onSelectCategory(state, action: PayloadAction<Categories.Category>) {
      if (!Array.isArray(state.currentMediakit.profile.categories)) {
        state.currentMediakit.profile.categories = [];
      }

      state.currentMediakit.profile.categories.push(action.payload);
    },
    onChangeActiveItem(state, action: PayloadAction<string | undefined>) {
      state.activeItem = action.payload;
      state.allElementsAreSelected = false;
    },
    onChangeLayoutProps(state, action: PayloadAction<Builder.GridLayoutProps>) {
      state.gridLayoutProps = action.payload;
    },
    onChangePreviewMode(
      state,
      action: PayloadAction<'mobile' | 'desktop' | undefined>,
    ) {
      state.previewMode = action.payload;
    },
    onChangeBreakpoint(
      state,
      action: PayloadAction<{
        newCols: number;
        newBreakpoint: LayoutBreakpoints;
      }>,
    ) {
      state.gridLayoutCols = action.payload.newCols;
      state.gridLayoutBreakpoint = action.payload.newBreakpoint;
    },
    onUpdateExtraDataItem(
      state,
      action: PayloadAction<{
        i: string;
        item: {
          value: any;
          key: keyof Builder.ExtraDataValues;
        };
      }>,
    ) {
      const uid = action.payload.i;
      const name = uid.split('_+_')[0] as ComponentNames;

      if (!state.extraData) {
        state.extraData = {};
      }

      if (!state.extraData[uid]) {
        if (name === ComponentNames.basics_service_list) {
          state.extraData[uid] = [];
        } else {
          state.extraData[uid] = {};
        }
      }

      const key = action.payload.item.key;

      if (name === ComponentNames.basics_service_list) {
        (state.extraData[uid] as any).push(action.payload.item.value);
      } else {
        (state.extraData[uid] as any)[key] = action.payload.item.value;
      }

      state.currentMediakit.profile.updated_at = `${Date.now()}`;
    },
    onUpdateReactGridItem(
      state,
      action: PayloadAction<{
        i: number;
        item: Partial<ILayout>;
      }>,
    ) {
      const index = action.payload.i;
      const item = action.payload.item;

      const breakpoint = state.gridLayoutBreakpoint;

      state.currentMediakit.allLayouts[breakpoint][index] = {
        ...state.currentMediakit.allLayouts[breakpoint][index],
        ...item,
      };
    },
    onUpdateExtraDataServiceListItem(
      state,
      action: PayloadAction<{
        i: string;
        item: {
          value: any;
          key: keyof Builder.ExtraDataValues;
        };
      }>,
    ) {
      const uid = action.payload.i;
      const name = uid.split('_+_')[0] as ComponentNames;

      if (!state.extraData) {
        state.extraData = {};
      }

      if (!state.extraData[uid]) {
        state.extraData[uid] = [];
      }

      if (name === ComponentNames.basics_service_list) {
        (state.extraData[uid] as any) = action.payload.item.value;
      }

      state.currentMediakit.profile.updated_at = `${Date.now()}`;
    },
    onChangeProfileAvatarShape(
      state,
      action: PayloadAction<profileAvatarShapes>,
    ) {
      state.currentMediakit.profile.avatar_shape = action.payload;
    },
    onChangeBuilderPreviewMode(
      state,
      action: PayloadAction<'mobile' | 'desktop'>,
    ) {
      state.builderPreviewMode = action.payload;
    },
    onChangeInternetConnection(
      state,
      action: PayloadAction<InternetConnectionStatus>,
    ) {
      state.internetConnectionStatus = action.payload;
    },
    onUpdateThemeDrawerHeight(
      state,
      action: PayloadAction<Partial<string | undefined>>,
    ) {
      state.themeDrawerHeight = action.payload;
    },
    onChangeFilter(
      state,
      action: PayloadAction<ComponentsFilters | undefined>,
    ) {
      state.selectedFilter = action.payload;
    },
    onUpdateTheme(
      state,
      action: PayloadAction<Partial<Builder.Mediakit.Theme>>,
    ) {
      state.themeDrawerHeight = '40vh';
      state.currentMediakit.theme = {
        ...state.currentMediakit.theme,
        ...action.payload,
      };
    },
    onUpdateThemeFonts(
      state,
      action: PayloadAction<Builder.Mediakit.Theme['fonts']>,
    ) {
      state.themeDrawerHeight = '40vh';

      state.currentMediakit.theme = {
        ...state.currentMediakit.theme,
        fonts: {
          ...state.currentMediakit?.theme?.fonts,
          ...action.payload,
        },
      };
    },
    onChangeThemeFilter(
      state,
      action: PayloadAction<ThemeFilter | number | undefined>,
    ) {
      state.themeFilter = action.payload;

      if (action.payload !== undefined) {
        state.themeDrawerHeight = '88vh';
      }
    },
    onChangeSocial(
      state,
      action: PayloadAction<Builder.CurrentMediakit.Profile['social_links']>,
    ) {
      state.currentMediakit.profile.social_links = action.payload;
    },
    onEditSocial(
      state,
      {
        payload: { index, ...rest },
      }: PayloadAction<
        Builder.CurrentMediakit.SocialLink & {
          index: number;
        }
      >,
    ) {
      if (state.currentMediakit.profile.social_links) {
        state.currentMediakit.profile.social_links[index] = rest;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(uploadAvatar.matchPending, (state) => {
        state.avatarState = FiniteStates.PENDING;
      })
      .addMatcher(uploadAvatar.matchRejected, (state) => {
        state.avatarState = FiniteStates.FAILED;
      })
      .addMatcher(uploadAvatar.matchFulfilled, (state, action) => {
        state.avatarState = FiniteStates.SUCCEEDED;

        state.currentMediakit.profile.avatar = action.payload.data.image;
      })

      .addMatcher(linkCrawl.matchPending, (state, action) => {
        const uid = action.meta.arg.originalArgs.uid;

        state.uploadImageStates[uid] = FiniteStates.PENDING;
      })
      .addMatcher(linkCrawl.matchFulfilled, (state, action) => {
        const uid = action.payload.uid;

        delete state.uploadImageStates[uid];

        if (!state.extraData[uid]) {
          state.extraData[uid] = {};
        }

        const data = action.payload.data;
        const { image_url, favicon_url, ...rest } = data;

        state.extraData[uid] = {
          ...state.extraData[uid],
          ...rest,
          image_url: image_url === '#' ? '/' : image_url,
          favicon_url: favicon_url === '#' ? '/' : favicon_url,
        };

        state.currentMediakit.profile.updated_at = `${Date.now()}`;
      })

      .addMatcher(uploadImage.matchPending, (state, action) => {
        const uid = action.meta.arg.originalArgs.data.get('uid');

        if (typeof uid === 'string') {
          if (action.meta.arg.endpointName === 'uploadImage') {
            state.uploadImageStates[uid] = 'uploadImage';
          } else {
            state.uploadImageStates[uid] = FiniteStates.PENDING;
          }
        }
      })
      .addMatcher(uploadImage.matchFulfilled, (state, action) => {
        const uid = action.payload.data.uid;

        if (!state.extraData[uid]) {
          state.extraData[uid] = {};
        }

        state.extraData[uid] = {
          ...state.extraData[uid],
          image_url: action.payload.data.image,
        };

        delete state.uploadImageStates[uid];

        state.currentMediakit.profile.updated_at = `${Date.now()}`;
      })

      .addMatcher(fetchMediakit.matchPending, (state) => {
        state.creatingQueryStatus = QueryStatus.pending;
      })
      .addMatcher(fetchMediakit.matchRejected, (state) => {
        state.creatingQueryStatus = QueryStatus.fulfilled;
      })
      .addMatcher(fetchMediakit.matchFulfilled, (state, action) => {
        try {
          const { mediakit, visits } = action.payload.data;
          const { components, theme } = mediakit.content;

          state.visits = visits;
          state.mediakit = mediakit;
          state.lastUpdatedAt = mediakit.updated_at;

          const { extraData, layoutComponents } = transformLData(components);

          state.currentMediakit.id = mediakit.id;
          state.currentMediakit.allLayouts = layoutComponents;

          if (theme?.palette) {
            if (!state.currentMediakit.theme) {
              state.currentMediakit.theme = {};
            }
            state.currentMediakit.theme.palette = theme.palette;
          }

          if (theme?.color_id) {
            if (!state.currentMediakit.theme) {
              state.currentMediakit.theme = {};
            }

            state.currentMediakit.theme.color_id = theme.color_id;
          }

          if (theme?.palette_id) {
            if (!state.currentMediakit.theme) {
              state.currentMediakit.theme = {};
            }

            state.currentMediakit.theme.palette_id = theme.palette_id;
          }

          if (theme?.shuffled_palette) {
            if (!state.currentMediakit.theme) {
              state.currentMediakit.theme = {};
            }

            state.currentMediakit.theme.shuffled_palette =
              theme.shuffled_palette || theme.palette;
          }

          if (theme?.primary_color) {
            if (!state.currentMediakit.theme) {
              state.currentMediakit.theme = {};
            }

            state.currentMediakit.theme.primary_color = theme.primary_color;
          }

          if (theme?.bg_image) {
            if (!state.currentMediakit.theme) {
              state.currentMediakit.theme = {};
            }

            state.currentMediakit.theme.bg_image = theme.bg_image;
          }

          if (theme?.bg_image_id) {
            if (!state.currentMediakit.theme) {
              state.currentMediakit.theme = {};
            }

            state.currentMediakit.theme.bg_image_id = theme.bg_image_id;
          }

          if (theme?.bg_image_type) {
            if (!state.currentMediakit.theme) {
              state.currentMediakit.theme = {};
            }

            state.currentMediakit.theme.bg_image_type = theme.bg_image_type;
          } else {
            if (!state.currentMediakit.theme) {
              state.currentMediakit.theme = {};
            }

            state.currentMediakit.theme.bg_image_type =
              BackgroundTypes.ViralMango;
          }

          if (theme?.fonts) {
            if (!state.currentMediakit.theme) {
              state.currentMediakit.theme = {};
            }

            state.currentMediakit.theme.fonts = theme.fonts;
          }

          extraData.forEach((props) => {
            const {
              i,
              link,
              title,
              value,
              align,
              domain,
              bg_color,
              image_url,
              favicon_url,
            } = props;

            if (!state.extraData) {
              state.extraData = {};
            }

            const name = i.split('_+_')[0] as ComponentNames;
            if (name === ComponentNames.basics_service_list) {
              state.extraData[i] = props.service_list || [];
            } else {
              state.extraData[i] = {
                link,
                title,
                value,
                align,
                domain,
                bg_color,
                image_url,
                favicon_url,
              };
            }
          });

          state.currentMediakit.profile = {
            ...state.currentMediakit.profile,
            ...transformProfileData(mediakit),
          };

          const allLayouts = state.currentMediakit.allLayouts;

          state.currentMediakit.template_id = mediakit.template_id;
          state.counter = allLayouts[LayoutBreakpoints.xxs].length;

          sessionStorage.setItem('last_updated_at', `${mediakit.updated_at}`);
        } catch (e) {
          console.error(e);
        }

        state.creatingQueryStatus = QueryStatus.fulfilled;
      })
      .addMatcher(fetchMediakits.matchFulfilled, (state, action) => {
        state.mediakits = action.payload.data.mediakits;
      })
      .addMatcher(fetchComponents.matchFulfilled, (state, action) => {
        try {
          state.tags = action.payload.data.tags;
          state.colors = action.payload.data.colors;
          state.components = action.payload.data.components;

          state.bgImages = [
            ...(state.bgImages || []),
            ...action.payload.data['bg-images'],
          ];

          const tmp = action.payload.data['font-groups'];

          state.fontGroups = Object.keys(tmp || {}).reduce((acc, curr) => {
            const c = tmp[curr];

            const c1 = c[1];
            const c2 = c[2];
            const c3 = c[3];

            acc[curr] = {
              ...c,
              1: {
                ...c1,
                ...(c1 as any).styles,
              },
              2: {
                ...c2,
                ...(c2 as any).styles,
              },
              3: {
                ...c3,
                ...(c3 as any).styles,
              },
            };

            return acc;
          }, [] as any);

          state.bgImageTags = action.payload.data['bg-image-tags'];

          if (!state.extraData) {
            state.extraData = {};
          }

          const components = Object.values(action.payload.data.components);
          const extra: any = components.reduce<Builder.Components.Items>(
            (acc, curr) => {
              if (curr.platform !== PLATFORM_ALL) {
                acc[curr.name] = curr;
              }

              return acc;
            },
            {} as Builder.Components.Items,
          );

          state.extraData = {
            ...(extra || {}),
            ...(state.extraData || {}),
          };
        } catch (e) {
          console.error(e);
        }
      })

      .addMatcher(fetchThemeColors.matchFulfilled, (state, action) => {
        state.theme.colors = action.payload.data.colors;

        if (!state.currentMediakit.theme) {
          const colors = Object.values(action.payload.data.colors);
          const indigo = colors.find((c) => c.name === 'Indigo');
          const defaultPalette = indigo?.palettes.find((p) => p.default);

          if (indigo && defaultPalette) {
            state.currentMediakit.theme = {
              color_id: indigo.id,
              primary_color: indigo.code,
              palette: defaultPalette.codes,
              palette_id: defaultPalette.palette_id,
              shuffled_palette: defaultPalette.codes,
            };
          }
        }
      })

      .addMatcher(editMediakit.matchPending, (state) => {
        state.isSavingMediakit = true;
      })
      .addMatcher(editMediakit.matchRejected, (state) => {
        state.isSavingMediakit = false;
      })
      .addMatcher(editMediakit.matchFulfilled, (state, action) => {
        state.isSavingMediakit = false;

        const updatedAt = action.payload?.data?.mediakit?.updated_at;

        if (updatedAt) {
          state.lastUpdatedAt = updatedAt;

          sessionStorage.setItem('last_updated_at', `${updatedAt}`);
        }
      })

      .addMatcher(authEndpoints.logout.matchFulfilled, () => {
        return initialState;
      });
  },
});

export const {
  onAddItem,
  onCopyItem,
  onEditSocial,
  onShowDrawer,
  onResetTheme,
  onBulkUpdate,
  onChangeName,
  onCloseDrawer,
  onUpdateTheme,
  onChangeSocial,
  onRemoveSocial,
  onChangeFilter,
  onChangeCtaText,
  onChangeTextarea,
  onRemoveCategory,
  onSelectLocation,
  onOpenShareModal,
  onCloseShareModal,
  onSelectCategory,
  onShowThemeDrawer,
  onCloseThemeDrawer,
  onChangeBreakpoint,
  onChangeActiveItem,
  onUpdateThemeFonts,
  onChangeAllLayouts,
  onChangeLayoutProps,
  onChangeThemeFilter,
  onChangePreviewMode,
  onUpdateReactGridItem,
  onUpdateExtraDataItem,
  onRemoveReactGridItem,
  onEmptyReactGridLayout,
  onRestorePreviousLayout,
  onUpdateThemeDrawerHeight,
  onChangeProfileAvatarShape,
  onChangeInternetConnection,
  onChangeBuilderPreviewMode,
  onChangeAllElementsSelection,
  onUpdateExtraDataServiceListItem,
} = builderSlice.actions;

/**
 * Helpers
 **/
const transformLData = (components: Builder.Mediakit.Component[]) => {
  return components.reduce<{
    layoutComponents: {
      [LayoutBreakpoints.sm]: ILayout[];
      [LayoutBreakpoints.xxs]: ILayout[];
    };
    extraData: {
      i: string;
      link?: string;
      title?: string;
      align?: string;
      value?: string;
      domain?: string;
      bg_color?: string;
      image_url?: string;
      favicon_url?: string;
      service_list?: any[];
    }[];
  }>(
    (
      acc,
      {
        name,
        link,
        align,
        title,
        value,
        mobile,
        domain,
        desktop,
        bg_color,
        image_url,
        favicon_url,
        service_list,
      },
      index,
    ) => {
      const uid = `${name}_+_${index}`;

      acc.layoutComponents[LayoutBreakpoints.sm].push({
        w: desktop.w,
        h: desktop.h,
        x: desktop.x ?? Infinity,
        y: desktop.y ?? Infinity,
        i: uid,
        moved: false,
        static: false,
      });

      acc.layoutComponents[LayoutBreakpoints.xxs].push({
        w: mobile.w,
        h: mobile.h,
        x: mobile.x ?? Infinity,
        y: mobile.y ?? Infinity,
        i: uid,
        moved: false,
        static: false,
      });

      if (dynamicCards.includes(name)) {
        acc.extraData.push({
          link,
          title,
          align,
          value,
          i: uid,
          domain,
          bg_color,
          service_list,
          image_url: image_url === '#' ? '/' : image_url,
          favicon_url: favicon_url === '#' ? '/' : favicon_url,
        });
      }

      return acc;
    },
    {
      extraData: [],
      layoutComponents: {
        [LayoutBreakpoints.sm]: [],
        [LayoutBreakpoints.xxs]: [],
      },
    },
  );
};

const transformProfileData = (mediakit: Builder.Mediakit.Mediakit) => {
  const { content, updated_at, ...rest } = mediakit;
  const { profile } = content;

  let categories = mediakit.categories;
  if (!Array.isArray(categories)) {
    categories = [];
  }

  const location = {
    value: mediakit.location || '',
    label: mediakit.location || '',
  };

  return {
    ...rest,
    ...profile,
    location,
    bio: mediakit.bio,
    avatar: mediakit.avatar,
    categories: categories.map((category) => ({
      label: category,
      value: Number(category),
    })),
  };
};
